import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { get, useFormContext, useWatch } from "react-hook-form";
import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { InputAutosuggestBlock } from "main/javascripts/components/form/InputAutosuggestBlock";
import { FlightOriginDestinationSuggestion } from "main/javascripts/models/FlightOriginDestinationSuggestion";
import { useAppDispatch, useAppSelector } from "main/javascripts/store";
import {
  flightDestinationInputDataSelector,
  flightDestinationSuggestionsSelector,
  flightItineraryDestinationInputDataSelector,
  flightItineraryDestinationSuggestionsSelector,
  loadingSelector,
  setFlightDestinationInputData,
  setFlightItineraryDestinationInputData,
} from "main/javascripts/features/flight/flightSuggestionSlice";
import { IColorStyle } from "main/javascripts/components/form/Label";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { FlightDestinationTypes } from "../../constants/FlightDestinationTypes";
import { isPhone } from "../../styles/base/responsiveStyle";
import { SuggestionFieldButton } from "../suggestionPicker/SuggestionFieldButton";
import { FullScreenModalDialog } from "../molecules/Modal/FullScreenModalDialog";
import { SuggestionPicker } from "../suggestionPicker/SuggestionPicker";
import { IBorderColorStyle } from "main/javascripts/styles/base/formStyle";
import { Suggestion } from "../../types/suggestion";
import { fontSize, letterSpacing } from "../../styles/base/typographyStyle";
import { FormNamespaces } from "../../constants/FormConstants";
import { defaultDestinationsSelector } from "../../slices/accountParamSlice";

export interface IProps {
  fetchFlightOriginDestinationSuggestions: any;
  defaultDestinations?: Suggestion[];
  suggestOptions?: any;
  itineraryGroup?: any;
  index?: number;
  labelColorStyleKey?: keyof IColorStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
  disableStopBodyScrolling?: boolean;
  onSelect?: any;
}

export const FlightDestinationBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    fetchFlightOriginDestinationSuggestions,
    defaultDestinations,
    suggestOptions,
    itineraryGroup,
    index,
    labelColorStyleKey,
    borderColorStyleKey,
    disableStopBodyScrolling,
    onSelect,
  } = props;

  const { t } = useTranslation(["label"]);
  const appDispatch = useAppDispatch();

  const isMultiCity = index !== undefined;

  const accountDefaultDestinations = useAppSelector(
    defaultDestinationsSelector
  );
  const destinations = isMultiCity
    ? useAppSelector((state) =>
        flightItineraryDestinationSuggestionsSelector(state, index || 0)
      )
    : useAppSelector((state) => flightDestinationSuggestionsSelector(state));
  const isLoading = useAppSelector(loadingSelector);
  const currentInputData = isMultiCity
    ? useAppSelector((state) =>
        flightItineraryDestinationInputDataSelector(state, index || 0)
      )
    : useAppSelector((state) => flightDestinationInputDataSelector(state));
  const currentInputText = currentInputData?.text;
  const currentInputLongName = currentInputData?.longName;

  // TODO: 旧reducerをそのまま使用
  // 新旧両方のaccountParamsから取得できるよう療法のreducerから取得している
  const accountDefaultDestinationsOld: any = useSelector<any>(
    (state) => state.accountParamsReducer.destinations
  );
  const breakpoint: any = useSelector<any>(
    (state) => state.breakpointReducer.breakpoint
  );

  const {
    setValue,
    control,
    formState: { errors },
    trigger,
  } = useFormContext();
  const fieldName = "destination";
  const idFieldName = "destination_id";
  const typeFieldName = "destination_type";
  const value =
    useWatch({
      control,
      name: fieldName,
    }) || "";
  const itineraryValue =
    useWatch({
      control,
      name: `${itineraryGroup}.${fieldName}`,
    }) || "";
  const itinerariesValue =
    useWatch({
      control,
      name: "itineraries",
    }) || [];

  const [displayedPicker, setDisplayedPicker] = useState(false);

  const destinationValue = itineraryGroup ? itineraryValue : value;
  const validDefaultDestinations = (
    ...values: (any[] | undefined)[]
  ): any[] | [] =>
    values.find((value) => value !== undefined && value.length > 0) ?? [];
  const suggestions = destinationValue
    ? destinations || []
    : validDefaultDestinations(
        defaultDestinations,
        accountDefaultDestinations,
        accountDefaultDestinationsOld
      );

  const inputTimeoutRef = useRef(null);
  const valueRef = useRef(destinationValue);
  valueRef.current = destinationValue;
  const currentInputLongNameRef = useRef(currentInputLongName);
  currentInputLongNameRef.current = currentInputLongName;
  const currentInputTextRef = useRef(currentInputText);
  currentInputTextRef.current = currentInputText;

  const val: (value: string) => string = (value: string) => {
    if (itineraryGroup) {
      return `${itineraryGroup}.${value}`;
    }
    return value;
  };

  const fetchDestinationSuggestions = (data: any) => {
    const type = FlightDestinationTypes.destination;
    fetchFlightOriginDestinationSuggestions(data, type, index);
  };

  useEffect(() => {
    return () => {
      clearTimeout(inputTimeoutRef.current);
    };
  }, []);

  const hidePicker: () => void = () => {
    setDisplayedPicker(false);
  };

  const onPickerButtonClick: () => void = () => {
    setDisplayedPicker(true);
  };

  const onSelected: (suggestion: FlightOriginDestinationSuggestion) => void = (
    suggestion: FlightOriginDestinationSuggestion
  ) => {
    clearTimeout(inputTimeoutRef.current);
    const inputText = valueRef.current;
    setValue(val(fieldName), suggestion.longName);
    setValue(val(idFieldName), suggestion.id);
    setValue(val(typeFieldName), suggestion.destinationType);

    if (
      itinerariesValue &&
      itinerariesValue.length > Number(index) + 1 &&
      !itinerariesValue[Number(index) + 1].origin
    ) {
      const name: string = itineraryGroup.replace(index, Number(index) + 1);
      setValue(`${name}.origin`, suggestion.longName);
      setValue(`${name}.origin_id`, suggestion.id);
      setValue(`${name}.origin_type`, suggestion.destinationType);
    }
    if (isMultiCity) {
      appDispatch(
        setFlightItineraryDestinationInputData({
          index: index || 0,
          data: { text: inputText, longName: suggestion.longName },
        })
      );
    } else {
      appDispatch(
        setFlightDestinationInputData({
          text: inputText,
          longName: suggestion.longName,
        })
      );
    }
    void trigger([val(fieldName), val(idFieldName)]);
    if (onSelect) {
      onSelect(suggestion);
    }
    if (displayedPicker) {
      hidePicker();
    }
  };

  const onClearInput: () => void = () => {
    clearTimeout(inputTimeoutRef.current);
    setValue(val(fieldName), "");
    setValue(val(idFieldName), null);
    setValue(val(typeFieldName), "");
    if (isMultiCity) {
      appDispatch(
        setFlightItineraryDestinationInputData({
          index: index || 0,
          data: { text: "", longName: "" },
        })
      );
    } else {
      appDispatch(setFlightDestinationInputData({ text: "", longName: "" }));
    }
  };

  const onFocus = () => {
    clearTimeout(inputTimeoutRef.current);
    // setValue(fieldName, currentInputText);
    const inputText = currentInputTextRef.current;
    setValue(val(fieldName), inputText);
    if (inputText) {
      fetchDestinationSuggestions({ text: inputText });
    }
  };

  const onBlur = () => {
    // onSelectedより先に呼ばれるため遅延させる
    inputTimeoutRef.current = setTimeout(() => {
      if (
        currentInputLongNameRef.current &&
        valueRef.current !== currentInputLongNameRef.current
      ) {
        setValue(val(fieldName), currentInputLongNameRef.current);
        void trigger(fieldName);
      }
    }, 400);
  };

  const error = get(errors, val(fieldName)) || get(errors, val(idFieldName));

  return (
    <div css={blockStyle}>
      {isPhone(breakpoint) ? (
        <>
          <SuggestionFieldButton
            namespace={FormNamespaces.flight}
            name={val(fieldName)}
            label={{
              namespace: FormNamespaces.flight,
              label: t("label:common.destination"),
              colorStyleKey: labelColorStyleKey,
            }}
            longName={destinationValue}
            placeholder={t("form.placeholders.flight.originPlaceholder")}
            onClick={onPickerButtonClick}
            borderColorStyleKey={borderColorStyleKey}
            error={error}
          />
          <FullScreenModalDialog
            displayed={displayedPicker}
            closeHandler={hidePicker}
            colorMode="light"
            disableStopBodyScrolling={disableStopBodyScrolling}
          >
            <div css={headerTitleStyle}>{t("label:common.destination")}</div>
            <SuggestionPicker
              name={val(fieldName)}
              suggestions={suggestions}
              placeholder={t("form.placeholders.flight.originPlaceholder")}
              isLoading={isLoading}
              suggestOptions={suggestOptions}
              onRequest={fetchDestinationSuggestions}
              onSelected={onSelected}
              onFocus={onFocus}
              onBlur={onBlur}
              onClear={onClearInput}
            />
          </FullScreenModalDialog>
        </>
      ) : (
        <InputAutosuggestBlock
          namespace={FormNamespaces.flight}
          label={{
            namespace: "common",
            label: t("label:common.destination"),
            colorStyleKey: labelColorStyleKey,
          }}
          input={{
            name: val(fieldName),
            value: destinationValue,
            type: "text",
            placeholder: t("form.placeholders.flight.originPlaceholder"),
          }}
          error={error}
          fetchDestinationSuggest={fetchDestinationSuggestions}
          suggestOptions={suggestOptions}
          suggestions={suggestions}
          onSelected={onSelected}
          onFocus={onFocus}
          onBlur={onBlur}
          onClear={onClearInput}
          disableOnFocusRequest
          alwaysShowSuggestions
          borderColorStyleKey={borderColorStyleKey}
        />
      )}
    </div>
  );
};

const blockStyle = css`
  flex: 1;
  flex-grow: 2;
  padding: ${space.atom} 0 0;
`;
const headerTitleStyle = css`
  position: absolute;
  top: 0;
  left: 50%;
  line-height: 3rem;
  font-size: ${fontSize.mediumHeading};
  font-weight: bold;
  letter-spacing: ${letterSpacing.text};
  transform: translateX(-50%);
`;
